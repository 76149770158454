import { Loader as StarmanLoader } from '@gbm/starman-next';

import styles from './loader.module.scss';

export function Loader() {
  return (
    <div
      aria-busy="true"
      aria-live="assertive"
      className={styles['general-loader']}
      id="general-loader-animation"
      role="alertdialog"
    >
      <StarmanLoader kind="light" />
    </div>
  );
}
