import { Typography } from '@gbm/starman-next';

import styles from './index.module.scss';

type Props = {
  valuePercentage: number;
  value: string;
};

export function CircleProgress({ value, valuePercentage }: Props) {
  return (
    <div className={styles['circle-progress']} data-progress={valuePercentage}>
      <div className={styles.content}>
        <span className={styles.counter}>
          <Typography weight="bold" variant="h4">
            {value}
          </Typography>
        </span>
      </div>
    </div>
  );
}
