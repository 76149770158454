import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { Typography } from '@gbm/starman-next';
import { useIntlTranslation } from '@gbm/snacks-i18n';

import { Logout } from './logout';
import { MenuItem } from './menu-item';
import { createMenuItems } from './utils/create-menu-items';

import { useAuth } from '../../../providers/auth';
import { useModules } from '../../../services/modules/useModules';
import { useUser } from '../../../services/users/useUser';

import { APP_ROUTES } from '../../../routes';
import { translations } from '../../../../translations';

import styles from './nav-bar.module.scss';

export function NavBar() {
  const { t } = useIntlTranslation();
  const { userIdentity } = useAuth();
  const { data: modulesData } = useModules();
  const { data: userData } = useUser(userIdentity.email);

  const modules = useMemo(
    () => createMenuItems({ modulesData, userData }),
    [modulesData, userData],
  );

  return (
    <aside className="border-r bg-muted/40 md:block max-w-[15rem] w-full">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex flex-col py-2 gap-2 border-b px-4 lg:px-6">
          <NavLink to="/" className="flex items-center gap-2 font-semibold">
            <span>GBM CMS</span>
          </NavLink>
          <Typography variant="small">
            <div className={styles.avatar}>{userIdentity?.givenName}</div>
          </Typography>
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            <MenuItem to={APP_ROUTES.root}>
              {t(translations.components.navigation.start)}
            </MenuItem>
            {modules}
          </nav>
        </div>
        <div className="mt-auto p-4">
          <Logout />
        </div>
      </div>
    </aside>
  );
}
