import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { Typography } from '@gbm/starman-next';
import { useIntlTranslation } from '@gbm/snacks-i18n';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../ui/components/dialog';
import { Button } from '../../ui/components/button';
import { IDLE_COUNTDOWN_TIME, IDLE_TIME } from './constants';
import { useAuth } from '../../providers/auth';
import { CircleProgress } from './progress';
import { formatMillisecondsToTime } from '../../utils/date-format';
import { translations } from '../../../translations';

const THROTTLE = 500;

export function InactivityDetector() {
  const { t } = useIntlTranslation();
  const { logout } = useAuth();
  const intervalId = useRef(0);
  const [inactive, setInactive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const onPrompt = useCallback(() => {
    !inactive && setInactive((value) => !value);
  }, [inactive]);

  const counterValues = useMemo(
    () => ({
      value: formatMillisecondsToTime(remainingTime || IDLE_COUNTDOWN_TIME),
      valuePercentage: Math.ceil(
        ((remainingTime || IDLE_COUNTDOWN_TIME) * 100) / IDLE_COUNTDOWN_TIME,
      ),
    }),
    [remainingTime],
  );

  const onActive = () => {
    message('keepSession');
    onMessage();
  };

  const onMessage = () => {
    setInactive(false);
    reset();
  };

  const { getRemainingTime, activate, message, reset } = useIdleTimer({
    crossTab: true,
    onActive,
    onMessage,
    onPrompt,
    promptBeforeIdle: IDLE_COUNTDOWN_TIME,
    syncTimers: 200,
    throttle: THROTTLE,
    timeout: IDLE_TIME,
  });

  useEffect(() => {
    if (inactive) {
      intervalId.current = window.setInterval(() => {
        const remaining = getRemainingTime();

        if (!remaining) {
          logout();
          clearInterval(intervalId.current);
          activate();
        }

        setRemainingTime(remaining);
      }, THROTTLE);
    }
  }, [inactive, activate, getRemainingTime, logout]);

  const onContinue = () => {
    activate();
  };

  return (
    <Dialog open={inactive}>
      <DialogContent withCloseButton={false}>
        <DialogHeader>
          <DialogTitle>
            <div>{t(translations.components.inactivityDetector.title)}</div>
            <div className="my-4 flex justify-center tabular-nums">
              <CircleProgress
                valuePercentage={counterValues.valuePercentage}
                value={counterValues.value}
              />
            </div>
          </DialogTitle>
          <DialogDescription>
            <Typography variant="body2">
              {t(translations.components.inactivityDetector.description)}
            </Typography>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="destructive" onClick={logout}>
            {t(translations.components.inactivityDetector.ctas.logout)}
          </Button>
          <Button onClick={onContinue}>
            {t(translations.components.inactivityDetector.ctas.continue)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
